
$(document).ready(function(){
    if (document.getElementsByClassName('brandsCarouselContainer')) {
        $('.brandsCarousel').slick({
            slidesToShow: 10,
            infinite: true,
            slidesToScroll: 1,
            arrows: false,

            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 8,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        autoplay: true
                    }
                },
                {
                    breakpoint: 390,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        autoplay: true
                    }
                }
            ]
        });

        $('.brandsCarouselContainer').removeClass('skeleton-loaderCarousel');

    }
});
